import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import filter from 'lodash/filter'
import Logo from '../components/logo'
import Icon from '../components/icon'
import SectionDefault from '../components/sectionDefault'
import Title from '../components/title'
import SubTitle from '../components/subTitle'
import SubscribeForm from '../components/subscribeForm'
import NavItem from '../components/navItem'
import { useInfoPagesData, pageLinks } from '../hooks/infoPages'

const Layout = ({ currentInfoPageSlug, currentFilterSlug, children }) => {
  const pageLinksData = filter(
    pageLinks(useInfoPagesData(), currentInfoPageSlug),
    (pageLink) => !pageLink.excludeOnFooter
  )

  const { allFile: bottomFilters } = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: { sourceInstanceName: { eq: "filters" } }
          sort: { fields: childMdx___frontmatter___order }
        ) {
          nodes {
            childMdx {
              slug
              frontmatter {
                title
              }
            }
          }
        }
      }
    `
  )

  const bottomFilterLinks = bottomFilters.nodes.map(({ childMdx: item }) => {
    return {
      to: `/${item.slug}/`,
      label: item.frontmatter.title,
      isCurrent: item.slug === currentFilterSlug,
    }
  })

  const navItemClassName = 'py-2 px-1 mx-3 my-4 hover:text-blue-600'

  return (
    <>
      <nav
        role="navigation"
        aria-label="main navigation"
        className="flex items-center max-w-4xl xl:max-w-6xl mx-auto"
      >
        <div className="flex flex-grow lg:flex-grow-0 items-center justify-between">
          <Link to="/" className={navItemClassName}>
            <Logo />
          </Link>
          <div className="flex items-center justify-end lg:hidden">
            <Link to="/tailwind-admin-templates/" className="p-1.5 md:p-3 mx-0.5">
              <span className="pl-0.5 md:pl-1.5">Tailwind</span>
            </Link>
            <Link to="/bulma-admin-template/" className="p-1.5 md:p-3 mx-0.5">
              <span className="pl-0.5 md:pl-1.5">Bulma</span>
            </Link>
            <a
              href="https://github.com/justboil"
              rel="noreferrer"
              target="_blank"
              className="p-1.5 md:p-3 inline-flex items-center  mx-0.5"
              title="GitHub"
            >
              <span className="pl-0.5 md:pl-1.5">GitHub</span>
            </a>
          </div>
        </div>

        <div id="navbar-menu" className="hidden lg:flex flex-1 items-center justify-between">
          <div className="flex items-center justify-start">
            <Link
              to="/tailwind-admin-templates/"
              className={navItemClassName}
              title="Tailwind CSS Admin Dashboard Templates"
            >
              Tailwind Admin Templates
            </Link>
            <Link
              to="/bulma-admin-template/"
              className={navItemClassName}
              title="Bulma Admin Dashboard Templates"
            >
              Bulma Admin Templates
            </Link>
          </div>

          <div className="flex items-center justify-end">
            <a
              href="https://justboil.github.io/docs/"
              rel="noreferrer"
              target="_blank"
              className={navItemClassName}
            >
              Docs
            </a>
            <Link to="/info/support/" className={navItemClassName}>
              Support
            </Link>
            <a
              href="https://github.com/justboil"
              rel="noreferrer"
              target="_blank"
              className={`${navItemClassName} inline-flex items-center`}
              title="GitHub"
            >
              <Icon icon="mdiGithub" />
            </a>
          </div>
        </div>
      </nav>

      {children}

      <SectionDefault className="my-48 text-center">
        <Title>Get updates</Title>
        <SubTitle>
          when we add new
          <br />
          or update current templates
        </SubTitle>
        <SubscribeForm />
      </SectionDefault>

      <SectionDefault className="pb-24" isFooter={true}>
        <div className="text-center max-w-4xl mx-auto">
          <p className="mb-12 font-semibold">©2018-2025, JustBoil.me</p>
          <div className="mb-9 flex flex-wrap justify-center">
            {pageLinksData.map((link) => (
              <NavItem
                key={link.to ?? link.href}
                to={link.to}
                href={link.href}
                small={false}
                current={link.isCurrent}
              >
                {link.label}
              </NavItem>
            ))}
          </div>
          <div className="-mb-3 flex flex-wrap justify-center">
            {bottomFilterLinks.map((link) => (
              <NavItem
                key={link.to ?? link.href}
                to={link.to}
                small={true}
                current={link.isCurrent}
              >
                {link.label}
              </NavItem>
            ))}
          </div>
          <div className="mt-24 flex justify-center">
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </div>
      </SectionDefault>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
